/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { FormSelect } from "shards-react";
import { EarthCard, EarthText } from '../../../components';

export default () => {
  return (
    <EarthCard>
      <EarthText size='xlarge' fontStyle='bold'>Preferences</EarthText>
      <div className='divider' />
      <div className='form-group'>
        <label>Language</label>
        <FormSelect>
          <option>English</option>
          <option>Spanish</option>
        </FormSelect>
      </div>
      <div className='form-group'>
        <label>Currency</label>
        <FormSelect>
          <option>USD</option>
          <option>ARS</option>
          <option>EUR</option>
        </FormSelect>

      </div>

    </EarthCard>
  )
}