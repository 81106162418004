import React from "react";
import { UserDetails } from "../../../components";

const Profile = () => (
  <div className='row justify-content-center'>
    <div className='col-12 col-md-8'>
      <UserDetails />
    </div>
  </div>
);

export default Profile;
