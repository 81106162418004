/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
} from "shards-react";
import { Link } from 'react-router-dom';

const ProjectOptions = [
  {
    backgroundImage: require("../../../assets/images/projects.png"),
    category: 'project',
    categoryTheme: "info",
    title: "Create new project",
    body: "Create new projects as well as issuing its tokens",
    pushTo: '/admin/project/create'
  },
  {
    backgroundImage: require("../../../assets/images/projects.png"),
    category: "project",
    categoryTheme: "info",
    title: "Edit existing projects",
    body: "Edit existing project as well as their price in the market.",
    pushTo: '/admin/project/edit'
  }
]

const UserOptions = [
  {
    backgroundImage: require("../../../assets/images/proof-identity.png"),
    category: 'users',
    categoryTheme: "danger",
    title: "Add or remove admins",
    body: "Manage access",
    pushTo: '/admin/users'
  },
]

const GlobalOptions = [
  {
    backgroundImage: require("../../../assets/images/global.jpeg"),
    category: 'global',
    categoryTheme: "success",
    title: "Manage alerts",
    body: "Add, modify or remove alerts",
    pushTo: '/admin/alerts'
  },
]

class Admin extends React.Component {

  render() {

    return (
      <Container fluid className="main-content-container px-4">
        <Row>
          {ProjectOptions.map((post, idx) => (
            <Col lg="4" sm="12" className="mb-4" key={idx}>
              <Link to={post.pushTo}>
                <Card small className="card-post card-post--aside card-post--1 hover-opacity">
                  <div
                    className="card-post__image"
                    style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                  >
                    <Badge
                      pill
                      className={`card-post__category bg-${post.categoryTheme}`}
                    >
                      {post.category}
                    </Badge>
                  </div>
                  <CardBody>
                    <h5 className="card-title">
                      <div className="text-fiord-blue" href="#">
                        {post.title}
                      </div>
                    </h5>
                    <p className="card-text d-inline-block mb-3">{post.body}</p>
                    <p className='card-title'>{post.location}</p>
                    <span className="text-muted">{post.date}</span>
                    <br />
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <Row>
          {UserOptions.map((post, idx) => (
            <Col lg="4" sm="12" className="mb-4" key={idx}>
              <Link to={post.pushTo}>
                <Card small className="card-post card-post--aside card-post--1 hover-opacity">
                  <div
                    className="card-post__image"
                    style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                  >
                    <Badge
                      pill
                      className={`card-post__category bg-${post.categoryTheme}`}
                    >
                      {post.category}
                    </Badge>
                  </div>
                  <CardBody>
                    <h5 className="card-title">
                      <div className="text-fiord-blue" href="#">
                        {post.title}
                      </div>
                    </h5>
                    <p className="card-text d-inline-block mb-3">{post.body}</p>
                    <p className='card-title'>{post.location}</p>
                    <span className="text-muted">{post.date}</span>
                    <br />
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <Row>
          {GlobalOptions.map((post, idx) => (
            <Col lg="4" sm="12" className="mb-4" key={idx}>
              <Link to={post.pushTo}>
                <Card small className="card-post card-post--aside card-post--1 hover-opacity">
                  <div
                    className="card-post__image"
                    style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                  >
                    <Badge
                      pill
                      className={`card-post__category bg-${post.categoryTheme}`}
                    >
                      {post.category}
                    </Badge>
                  </div>
                  <CardBody>
                    <h5 className="card-title">
                      <div className="text-fiord-blue" href="#">
                        {post.title}
                      </div>
                    </h5>
                    <p className="card-text d-inline-block mb-3">{post.body}</p>
                    <p className='card-title'>{post.location}</p>
                    <span className="text-muted">{post.date}</span>
                    <br />
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default Admin;