/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import { FormCheckbox, ListGroup, ListGroupItem } from "shards-react";

export const SidebarActions = ({ title }) => (
  <ListGroup flush>
    <ListGroupItem className="p-3">
      <span className="d-flex mb-2">
        <i className="material-icons mr-1" />
        <strong className="mr-1">Visible</strong>
        <FormCheckbox className='ml-auto' toggle small defaultChecked />
      </span>
    </ListGroupItem>
  </ListGroup>
);

SidebarActions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarActions.defaultProps = {
  title: "Actions"
};
