import React, { Component } from 'react';
import { EarthButton, EarthText } from '../../../components';
import { toast } from 'react-toastify';
import { users } from '../../../controllers/requests';
import './styles.scss';

export default class EarthConfirmationEmail extends Component {
  constructor(props) {
    super(props);
    this.state = { POI: null, POR: null };
  }

  onSendMailAgain = async () => {
    const response = await users.resendActivationEmail();
    if (response) {
      toast.success('Confirmation email sent!');
      this.props.onCancel();
    } else { 
      toast.error('Error');
    }
  }

  onCancel = () => {
    this.props.onCancel()
  }

  render() {
    return (
      <div className="card EarthKyc">
        <div className="card-body">
          <div className="p-2">
            <EarthText size="large" className="EarthKyc__title_text mb-5 text-center ">
              Activate your account using the link in your email address
            </EarthText>

            <div className='row'>
              <div className='col-4 offset-4' style={{ textAlign: 'center' }}>
                <img src={require('../../../assets/images/inbox.png')} height="70" alt="earth-proof" className="EarthProof__image" />
              </div>
            </div>

            <EarthText
              size="small"
              className="EarthKyc__skip_text text-center mt-5"
              onClick={this.onSendMailAgain}
            >
              Send email again
            </EarthText>

            <EarthButton
              size="large"
              className="EarthKyc__button mt-5 p-3" flex
              onClick={this.onCancel}
            >
              OK, I'LL BE BACK
            </EarthButton>
          </div>
        </div>
      </div>
    );
  }
}
