import cn from 'classnames';
import React from 'react';
import './styles.scss';

const types = {
  admin: 'supervisor_account',
  purchase: 'attach_money',
  notification: 'notifications'
}
export const EarthActionAvatar = ({ type, className }) => {
  const earthLetterCircleStyles = `EarthLetterCircle EarthLetterCircle__${type} material-icons`;

  return (
    <i className={cn(earthLetterCircleStyles, className)}>
      {types[type]}
    </i>
    // <p className={cn(earthLetterCircleStyles, className)}>
    //   {letter.toUpperCase()}
    // </p>
  );
};
