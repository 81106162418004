import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLoading, updateUser } from '../../store/actions/root';
import { users } from '../../controllers/requests';
import { toast } from 'react-toastify';
import { EarthTabs, EarthTabItem } from '../../components';
import EarthSignIn from './EarthSignIn/EarthSignIn';
import EarthSignUp from './EarthSignUp/EarthSignUp';
import EarthConfimationEmail from './EarthConfimationEmail/EarthConfimationEmail';
import { EarthKyc } from './EarthKyc/EarthKyc';

class EarthAuth extends Component {

  state = { tab: 'signin', kyc: false, active: null, loggedIn: false };

  onSignUpSubmit = async (earthName, email, password) => {
    if (await users.signUp(earthName, email, password)) {
      toast.success('Account created!');
      this.setState({ tab: 'signin' });
    } else {
      toast.error('Failed to sign up!');
    }
  }

  onSignInSubmit = result => result && this.setState({ ...result });
  onKycSkip = () => this.props.setLoading(true);
  onKycFill = (POI, POR) => this.props.setLoading(true);
  swapTab = tab => !this.state.loggedIn && this.setState({ tab });

  renderInProcess = () => {
    const { active, kyc, loggedIn } = this.state;
    if(!loggedIn) return <EarthSignIn onSignInSubmit={this.onSignInSubmit} />
    if(!active) return <EarthConfimationEmail onCancel={() => this.setState({ loggedIn: false })} />
    if(!kyc) return <EarthKyc onKycSkip={this.onKycSkip} onKycFill={this.onKycFill} />
    this.props.setLoading(true);
  }

  render() {
    const { tab, kyc } = this.state;
    return (
      <div className="wrapper-page">
        <div className="row">
          <div className="col-md-12">
            <EarthTabs>
              <EarthTabItem
                onClick={() => this.swapTab('signin')}
                icon=""
                title="Sign In"
                isActive={tab === 'signin'}
              >
                {
                  this.renderInProcess()
                }
              </EarthTabItem>

              <EarthTabItem
                onClick={() => { !kyc && this.swapTab('signup')}}
                icon=""
                title="Sign Up"
                isActive={tab === 'signup'}
              >
                <EarthSignUp onSignUpSubmit={this.onSignUpSubmit} />
              </EarthTabItem>
            </EarthTabs>
          </div>
        </div>
      </div>
    );
  }
}

EarthAuth.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchtoProps = dispatch => {
  return {
    setLoading: state => dispatch(setLoading(state)),
    updateUser: state => dispatch(updateUser(state)),
  };
}

export default connect(null, mapDispatchtoProps)(EarthAuth);