import React, { useEffect, useState } from 'react';
import { EarthText, EarthCard } from '../../components'
import Spinner from 'react-spinner-material';
import { balances } from '../../controllers/requests';
import { ProspectusCard } from '../../components';

export default () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    balances.getBalances().then(tokens => {
      setTokens(tokens);
      setLoading(false);
    });
  }, [])

  return (
    <EarthCard>
      <EarthText fontStyle='bold'>Project Prospectus</EarthText>
      <div className='divider mb-4' />
      {
        loading ?
          <div style={{ width: 120, margin: 'auto', paddingTop: 100 }}>
            <Spinner size={80} spinnerColor={'#fff'} visible={true} />
          </div>
          :
          tokens.map(token => <ProspectusCard {...token} />)
      }
    </EarthCard>
  )
}
