import axios from 'axios';
import { conf } from '../../utils';
import { getToken } from './index';
import FormData from 'form-data';
import HTTPError from '../HTTPError';

export const projects = {
  createProject: async (project) => {
    delete project.publishing;
    const url = conf('apiUrl') + '/projects';
    const formData = new FormData();
    Object.entries(project).map(entry => {
      if (entry[0] !== 'images' && entry[0] !== 'avatar') {
        formData.set(
          entry[0],
          (typeof entry[1]) === 'object' ? JSON.stringify(entry[1]) : entry[1]
        );
      }
    });
    project.images.map((image, idx) => formData.append('images', image, image.name));
    formData.append('avatar', project.avatar, project.avatar.name);

    try {
      const res = await axios.post(url, formData, { headers: { 'content-type': 'multipart/form-data', Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  updateProject: async (project) => {
    const url = conf('apiUrl') + `/projects/${project.urlPath}`;
    delete project.publishing;
    delete project.images;
    delete project.needs;
    delete project._id
    try {
      const res = await axios.patch(url, { ...project }, { body: project, headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  deleteProject: async (project) => {
    const url = conf('apiUrl') + `/projects/${project.urlPath}`;
    try {
      const res = await axios.patch(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getProjects: async () => {
    const url = conf('apiUrl') + `/projects`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getProjectById: async urlPath => {
    const url = conf('apiUrl') + `/projects/` + urlPath;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  buyShares: async (urlPath, amount, billing) => {
    const url = conf('apiUrl') + `/projects/` + urlPath + '/buy/' + amount;
    try {
      const res = await axios.post(url, { billing }, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  }
}