export const updateTotalBalance = totalBalance => (
  {
    type: 'UPDATE_TOTAL_BALANCE',
    payload: totalBalance,
  }
);

export const toggleSidebar = () => (
  {
    type: 'TOGGLE_SIDEBAR',
  }
);

export const updateSavingsBalance = savingBalance => (
  {
    type: 'UPDATE_SAVINGS_BALANCE',
    payload: savingBalance,
  }
);

export const updateLastInterest = lastInterest => (
  {
    type: 'UPDATE_LAST_INTEREST',
    payload: lastInterest,
  }
);

export const updateActivity = activity => (
  {
    type: 'UPDATE_ACTIVITY',
    payload: activity,
  }
);

export const updateExchangeRates = rates => (
  {
    type: 'UPDATE_EXCHANGE_RATES',
    payload: rates,
  }
);

export const updateChoosenFiat = choosenFiat => (
  {
    type: 'UPDATE_CHOOSEN_FIAT',
    payload: choosenFiat,
  }
);

export const setCurrencyModal = state => (
  {
    type: 'SET_CURRENCY_MODAL',
    payload: state,
  }
);

export const updateBackup = state => (
  {
    type: 'UPDATE_BACKUP',
    payload: state,
  }
);

export const setLoading = state => (
  {
    type: 'SET_LOADING',
    payload: state,
  }
);

export const updateUser = state => (
  {
    type: 'UPDATE_USER',
    payload: state,
  }
);

export const setLock = state => (
  {
    type: 'SET_LOCK',
    payload: state,
  }
);