import React, { Component } from 'react';
import { EarthButton, EarthModal, EarthText, EarthProof } from '../../../components';
import './styles.scss';

import proofIdentity from '../../../assets/images/proof-identity.png';
import proofResidency from '../../../assets/images/proof-residency.png';

export class EarthKyc extends Component {
  constructor(props) {
    super(props);
    this.state = { POI: null, POR: null, showModal: false };
  }

  setModal = showModal => this.setState({ showModal });

  onPOIUpload = POI => {
    this.setState({ POI });
  }

  onPORUpload = POR => {
    this.setState({ POR });
  }

  render() {
    const { onKycSkip, onKycFill } = this.props;
    const { POI, POR, showModal } = this.state;
    return (
      <div className="card EarthKyc">
        <div className="card-body">
          <div className="p-2">
            <EarthText size="large" className="mb-5 text-center ">
              In order to authenticate your account you need to complete the following steps
            </EarthText>

            <EarthProof
              title="Proof of Residency"
              isChecked={POR}
              onUpload={this.onPORUpload}
              description="Utility Bill issued wihin 3 months"
              image={proofResidency}
            />

            <EarthProof
              isChecked={POI}
              title="Proof of Identity"
              onUpload={this.onPOIUpload}
              description="Passport scan or clear photo"
              image={proofIdentity}
            />

            <EarthText
              onClick={() => this.setModal(true)}
              size="small"
              className="EarthKyc__skip_text text-center mt-5"
            >
              Skip for now, i'll do it later
          </EarthText>

            <EarthButton
              disabled={POI === null || POR === null} size="large"
              className="EarthKyc__button mt-5 p-3" flex
              onClick={() => onKycFill(POI, POR)}
            >
              VALIDATE YOUR ACCOUNT
            </EarthButton>

            <EarthModal 
              onClose={() => this.setModal(false)}
              show={showModal}
            >
              <EarthText
                size="xsmall"
                className="EarthKyc__title_text text-center mb-1"
              >
                Keep in mind if you skip the identity verification <br />
                process, you will not be able to participate <br />
                in any investments.
                Accredited Investors only, until platform is public.
            </EarthText>

              <EarthText className="text-center">
                <EarthButton className="EarthKyc__modal_button text-bold mt-4"
                  onClick={onKycSkip}
                >
                  OK, I AGREE
                </EarthButton>
              </EarthText>
            </EarthModal>
          </div>
        </div>
      </div>
    );
  }
}
