import React from 'react';
import { 
  FacebookIcon, FacebookShareButton,
  TwitterIcon, TwitterShareButton,
  EmailIcon, EmailShareButton
} from 'react-share';

export function ShareBatch() {
  return(
    <div className='row justify-content-center mt-3'>
      <FacebookShareButton
        url={'https://google.com'}
        className="Demo__some-network__share-button mr-3">
        <FacebookIcon
          size={32}
          round />
      </FacebookShareButton>
      <TwitterShareButton
        url={'https://google.com'}
        className="Demo__some-network__share-button mr-3">
        <TwitterIcon
          size={32}
          round />
      </TwitterShareButton>
      <EmailShareButton
        url={'https://google.com'}
        className="Demo__some-network__share-button mr-3">
        <EmailIcon
          size={32}
          round />
      </EmailShareButton>
    </div>
  )
}