import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import EarthAuth from '../EarthAuth/EarthAuth';
import { EarthMain } from '../EarthMain/EarthMain';
import './styles.scss';
import { setLoading } from '../../store/actions/root';
import { connect } from 'react-redux';
import AppLoading from '../AppLoading';
import { ToastContainer } from 'react-toastify';
import Lock from './Lock/Lock';

class EarthApp extends Component {
  state = { loggedIn: false };

  onLoadingComplete = loggedIn => {
    this.props.setLoading(false);
    this.setState({ loggedIn });
  };

  render() {
    const { appLoading, locked } = this.props;
    const { loggedIn } = this.state;
    let layout = null;
    layout = (
      <div>
        {!loggedIn && (
          <>
            <Route path="/login" component={EarthAuth} />
            <Redirect from="*" to="/login" />
          </>
        )}
        {loggedIn && <EarthMain />}
      </div>
    );

    return (
      <div className="App">
        {appLoading ? (
          <AppLoading onLoadingComplete={this.onLoadingComplete} />
        ) : locked ? (
          <Lock />
        ) : (
          layout
        )}
        <ToastContainer position="bottom-center" />
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    appLoading: state.root.loading,
    locked: state.root.locked,
  };
};

const mapDispatchToProps = dispatch => ({
  setLoading: state => dispatch(setLoading(state)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(EarthApp);
