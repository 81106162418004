import React, { Component } from 'react';
import { Row } from 'shards-react';
import { EarthText } from '../../../components';
import './styles.scss';
import { markets } from '../../../controllers/requests';

export class EarthMarketList extends Component {
  constructor(props) {
    super(props);
    this.state = { markets: [] }
  }

  componentDidMount() {
    markets.getMarkets()
      .then(markets => this.setState({ markets }))
  }

  navigateMiddleware = id => {
    const { navigate } = this.props;
    if(navigate) {
      navigate(id)
    }
  }

  render() {
    const { markets } = this.state;
    const { selectedMarket } = this.props;
    return (
      <div>
        <ul className='market-list'>
          <li className='equityToken-element-no-avatar text-center mb-2'>
            <Row>
              <div className='col'>
                <EarthText size='xxsmall' fontStyle='bold'>
                  Token
                </EarthText>
              </div>
              <div className='col'>
                <EarthText size='xxsmall' fontStyle='bold'>
                  Price
                </EarthText>
              </div>
              <div className='col'>
                <EarthText size='xxsmall' fontStyle='bold'>
                  Vol.
                </EarthText>
              </div>
              <div className='col'>
                <EarthText size='xxsmall' fontStyle='bold'>
                  Change
                </EarthText>
              </div>
            </Row>
          </li>
          {
            markets.map(
              ({ equityToken, status, volatibility, initialPrice, lastPrice, id }) => (
                <li 
                  className={`token-element-no-avatar ${selectedMarket === equityToken ? 'active' : ''}`} 
                  onClick={() => this.navigateMiddleware(equityToken)}
                >
                  <Row className='text-center'>
                    <div className='col'>
                      <EarthText size='xxsmall' fontStyle='bold'>
                        {equityToken}
                      </EarthText>
                    </div>
                    <div className='col'>
                      <EarthText size='xxsmall' fontStyle='bold'>
                        {lastPrice}
                      </EarthText>
                    </div>
                    <div className='col'>
                      <EarthText size='xxsmall' fontStyle='bold'>
                        {volatibility}%
                      </EarthText>
                    </div>
                    <div className='col'>
                      <EarthText size='xxsmall' fontStyle='bold'>
                        { (((lastPrice / initialPrice) * 100) - 100) + '%' }
                      </EarthText>
                    </div>
                  </Row>
                </li>
              )
            )
          }
        </ul>
      </div>
    );
  }
}