export const branding = {
  brandName: 'RegenLand Fund', 
  earthRewards: 'Rewards',
  username: 'Username',
  lightLogo: require('./branding/regenland-white.png'),
  darkLogo: require('./branding/regenland-black.png'),
  webPage: 'regenland.fund',
  apiUrl: 'https://api.liquidtoken.net',
  equityWallet: 'Account',
  rewardsWallet: 'Rewards'
}