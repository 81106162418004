import React from "react";
import { EarthText } from '../../components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrencyModal, setLoading } from '../../store/actions/root';

class Profile extends React.Component {
  onLogOut = () => {
    sessionStorage.clear();
    this.props.setLoading(true);
  };

  render() {
    const { earthName } = this.props;
    return (
        <div className="navbar-user border-left">
          <button
            type="button"
            className="btn dropdown-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
          >
            <i className="material-icons user-icon">account_circle</i>
            <EarthText size="small" fontStyle="normal" color='white'>
              {earthName.toUpperCase()}
            </EarthText>
            <i className="material-icons ml-2" style={{ color: 'white' }}>arrow_drop_down</i>
          </button>

          <div
            className="dropdown-menu dropdown-menu-right user-dropdown"
            aria-labelledby="dropdownMenuButton"
          >
            <Link to="/profile">
              <span className="dropdown-item">
                <i className="material-icons m-r-5">account_circle</i>
                <EarthText className="text" size="small" fontStyle="normal">
                  User Profile
                </EarthText>
              </span>
            </Link>
            <span className="dropdown-item currency-dropdown d-flex d-sm-none" onClick={() => this.props.setCurrencyModal(true)}>
              <i className="material-icons m-r-5">money</i>
              <EarthText className="text" size="small" fontStyle="normal">
                {'Currency'}
              </EarthText>
            </span>
            <Link to="/settings/security">
              <span className="dropdown-item">
                <i className="material-icons m-r-5">security</i>
                <EarthText className="text" size="small" fontStyle="normal">
                  Security
                </EarthText>
              </span>
            </Link>
            <Link to="/settings">
              <span className="dropdown-item">
                <i className="material-icons m-r-5">build</i>
                <EarthText className="text" size="small" fontStyle="normal">
                  Configuration
                </EarthText>
              </span>
            </Link>
            <span className="dropdown-item" onClick={this.onLogOut}>
              <i className="material-icons m-r-5">power_settings_new</i>
              <EarthText className="text" size="small" fontStyle="normal">
                Logout
              </EarthText>
            </span>
          </div>
        </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    earthName: state.root.user.earthName,
  };
}

const mapDispatchToProps = dispatch => ({
  setCurrencyModal: state => dispatch(setCurrencyModal(state)),
  setLoading: state => dispatch(setLoading(state))
});

export default connect(mapStatetoProps, mapDispatchToProps)(Profile);

