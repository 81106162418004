import React, { Component } from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Row, Col, Badge, FormSelect, Button } from "shards-react";
import { SidebarActions, SidebarCategories } from '../../components'
import { EarthButton } from "../EarthButton/EarthButton";
import { SDGButtons } from "../SDGButtons/SDGButtons";
import countryNames from '../../utils/countries';
import { getImage, branding } from '../../utils'
import FileZone from 'react-files';
import "react-quill/dist/quill.snow.css";
import "./quill.scss";
import { Spinner } from '../../components';
import { isAlpha, isNumeric } from "../../utils/validations";

export class ProjectEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '', location: '', category: '', smallDescription: '', daysToGo: '', owner: '',
      rewardsToken: '', volatibility: '', price: '', sdgs: {}, story: '', needs: {},
      description: '', urlPath: '', equityToken: '', images: [], avatar: '', minInvestment: '',
      fundTarget: '', publishing: false, impactField: '', initialRates: [], selectImpact: 'carbon'
    }
  }

  componentDidMount() {
    const { editContent } = this.props;
    if (editContent) this.setState({ ...editContent });
  }

  onAddMetric = () => {
    const { impactField } = this.state;
    this.setState({ initialRates: { carbon: impactField } });
  }
  onInputChange = (field, { target: { value } }) => this.setState({ [field]: value });
  onUpload = images => this.setState({ images });
  onUploadAvatar = avatarList => this.setState({ avatar: avatarList[0] });
  removeImage = file => {
    const { images } = this.state;
    images.map((image, id) => {
      if (image.id === file.id) images.splice(id, 1);
    })
    this.setState({ images });
    this.refs.images.removeFile(file);
  }

  onSubmit = e => {
    e.preventDefault()
    this.setState({ publishing: true });
    this.props.onSubmit(this.state).then(() => this.setState({ publishing: false }));
  }

  render() {
    const {
      name, smallDescription, daysToGo, owner, images, avatar, minInvestment, location, category,
      description, urlPath, equityToken, rewardsToken, volatibility, price, fundTarget, story,
      sdgs, publishing, impactField, initialRates, selectImpact
    } = this.state;
    const { editContent } = this.props;

    const isMinInvestmentValid = isNumeric(minInvestment);
    const isVolatibilityValid = isNumeric(volatibility);
    const isRewardsTokenValid = isAlpha(rewardsToken);
    const isEquityTokenValid = isAlpha(equityToken);
    const isFundTargetValid = isNumeric(fundTarget);
    const isDaysToGoValid = isNumeric(daysToGo);
    const isUrlPathValid = isAlpha(urlPath);
    const isNameValid = isAlpha(name, true);
    const isPriceValid = isNumeric(price);
    const isOwnerValid = isAlpha(owner);
    const isSmallDescriptionValid = Boolean(smallDescription);
    const isMetricsValid = Boolean(initialRates.carbon);
    const isDescriptionValid = Boolean(description);
    const isLocationValid = Boolean(location);
    const isCategoryValid = Boolean(category);
    const isStoryValid = Boolean(story);
    const isAvatarValid = Boolean(avatar);
    const isImagesValid = images && images.length > 0;

    const isPublishable = (
      isSmallDescriptionValid && isDescriptionValid && isMinInvestmentValid && isMetricsValid &&
      isVolatibilityValid && isRewardsTokenValid && isEquityTokenValid && isImagesValid &&
      isFundTargetValid && isDaysToGoValid && isUrlPathValid && isNameValid &&
      isPriceValid && isOwnerValid && isLocationValid && isCategoryValid && isStoryValid && isAvatarValid
    );
    return (
      <Card small className="mb-3">
        <CardBody>
          <Form className="add-new-post" onSubmit={this.onSubmit}>
            <Row form>
              <Col lg='10' md="12" className="form-group">
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">Project Name</label>
                    <FormInput
                      required className="mb-3" placeholder="Project Name" invalid={name && !isNameValid}
                      value={name} onChange={e => this.onInputChange('name', e)}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label htmlFor="feInputLocation">Location</label>
                    <FormSelect value={location} onChange={e => this.onInputChange('location', e)}>
                      <option>Choose...</option>
                      {countryNames.map(({ name }, idx) => <option value={name} key={idx}>{name}</option>)}
                    </FormSelect>
                  </Col>
                  <Col md="3" className="form-group">
                    <label htmlFor="feInputCategory">Category</label>
                    <FormSelect value={category} onChange={e => this.onInputChange('category', e)}>
                      <option>Choose...</option>
                      <option>Grasslands</option>
                      <option>Rainforest</option>
                      <option>Eco Community</option>
                      <option>Earth Saver</option>
                    </FormSelect>
                  </Col>
                </Row>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">Small Description</label>
                    <FormInput
                      className="mb-3" placeholder="2 or 3 sentences about your projects..." invalid={smallDescription && !isSmallDescriptionValid}
                      value={smallDescription} onChange={e => this.onInputChange('smallDescription', e)}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label htmlFor="feFirstName">Days to go</label>
                    <FormInput
                      className="mb-3 days-to-go" placeholder="Days.." invalid={daysToGo && !isDaysToGoValid}
                      value={daysToGo} onChange={e => this.onInputChange('daysToGo', e)}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label htmlFor="feFirstName">Project Owner</label>
                    <FormInput
                      className="mb-3" placeholder="Owner..." invalid={owner && !isOwnerValid}
                      value={owner} onChange={e => this.onInputChange('owner', e)}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <label htmlFor="feFirstName">Description</label>
                    <FormInput
                      className="mb-3" placeholder="A few more words" invalid={description && !isDescriptionValid}
                      value={description} onChange={e => this.onInputChange('description', e)}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md='2'>
                    <label htmlFor="feFirstName">Project ID</label>
                    <FormInput
                      className="mb-3" placeholder="How people find your project?" invalid={urlPath && !isUrlPathValid}
                      value={urlPath} onChange={e => this.onInputChange('urlPath', e)} disabled={Boolean(editContent)}
                    />
                  </Col>
                  <Col md='2'>
                    <label htmlFor="feFirstName">'</label>
                    <FormInput disabled className="mb-3" placeholder={`${branding.webPage}/project/${urlPath}`} />
                  </Col>
                  <Col md='2'>
                    <label htmlFor="feFirstName">Equity token name</label>
                    <FormInput
                      className="mb-3" placeholder="3 letter identificator" invalid={equityToken && !isEquityTokenValid}
                      value={equityToken} onChange={e => this.onInputChange('equityToken', e)}
                    />
                  </Col>
                  <Col md='2'>
                    <label htmlFor="feFirstName">Rewards token name</label>
                    <FormInput
                      className="mb-3" placeholder="3 letter identificator" invalid={rewardsToken && !isRewardsTokenValid}
                      value={rewardsToken} onChange={e => this.onInputChange('rewardsToken', e)}
                    />
                  </Col>
                  <Col md='2'>
                    <label htmlFor="feFirstName">Price volatibility</label>
                    <FormInput
                      className="mb-3" placeholder="Percentage" invalid={volatibility && !isVolatibilityValid}
                      value={volatibility} onChange={e => this.onInputChange('volatibility', e)}
                    />
                  </Col>
                  <Col md='2'>
                    <label htmlFor="feFirstName">Token price</label>
                    <FormInput
                      className="mb-3" placeholder="Value" invalid={price && !isPriceValid}
                      value={price} onChange={e => this.onInputChange('price', e)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg='2' md='12' className='form-group'>
                <Card className='h-100'>
                  <SidebarActions />
                  <SidebarCategories />
                </Card>
              </Col>
            </Row>
            <Row form>
              <Col md='2'>
                <label htmlFor="feFirstName">Investment target</label>
                <FormInput
                  className="mb-3" placeholder="Value" invalid={fundTarget && !isFundTargetValid}
                  value={fundTarget} onChange={e => this.onInputChange('fundTarget', e)}
                />
              </Col>
              <Col md='2'>
                <label htmlFor="feFirstName">Minimum investment</label>
                <FormInput
                  className="mb-3" placeholder="Value" invalid={minInvestment && !isMinInvestmentValid}
                  value={minInvestment} onChange={e => this.onInputChange('minInvestment', e)}
                />
              </Col>
            </Row>
            <label>Impact rates (Amount of impact produced a day for each dollar)</label>
            <Row form>
              <div className='form-group col-12 col-md-2'>
                <FormInput value={impactField} onChange={e => this.onInputChange('impactField', e)} />
              </div>
              <div className='form-group col-12 col-md-2'>
                <FormSelect value={selectImpact} onChange={e => this.onInputChange('selectImpact', e)}>
                  <option value='carbon'>Carbon</option>
                </FormSelect>
              </div>
              <div className='form-group col-md-2'>
                <Button onClick={this.onAddMetric} className='w-100'>Add</Button>
              </div>
            </Row>
            <ul>
              {
                initialRates.carbon &&
                <li>{`${initialRates.carbon} carbon a month per each dollar.`}</li>

              }
            </ul>
            <label>SDGs</label>
            <div className='mb-4'>
              <SDGButtons values={sdgs} onChange={sdgs => this.setState({ sdgs })} />
            </div>

            <label htmlFor="feFirstName">Story</label>
            <Row>
              <Col lg='8' md='8'>
                <ReactQuill
                  className="add-new-post__editor mb-4" value={story}
                  onChange={story => this.setState({ story })} invalid={true}
                />
              </Col>
              <Col lg='4' md='4'>
                <FileZone
                  ref='images'
                  accepts={['image/png', 'image/jpg', 'image/jpeg']}
                  style={{ height: 200 }}
                  className='EarthProof mb-4 h-100 mt-0'
                  onChange={this.onUpload}
                  multiple
                  maxFiles={3}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  Drag or upload images
                </FileZone>
                <FormInput placeholder='Youtube Video Link' />
                <div className='col row image-gallery-upload'>
                  {
                    images && images.map((image, idx) => {
                      return (
                        <div key={idx} style={{ position: 'relative' }}>
                          <img alt='icon' src={(image.preview && image.preview.url)
                            ||
                            getImage(image)} className='image-gallery-upload-item'
                          />
                          <Badge
                            id={image.id}
                            pill theme="danger" className='remove-button'
                            onClick={() => this.removeImage(image)}
                          >
                            X
                          </Badge>
                        </div>
                      )
                    })
                  }
                </div>
                <Row>
                  <img alt='icon' src={(avatar.preview && avatar.preview.url)
                    ||
                    getImage(avatar)} className='image-gallery-upload-item'
                  />
                  <FileZone
                    ref='images'
                    accepts={['image/png', 'image/jpg', 'image/jpeg']}
                    style={{ height: 200 }}
                    className='EarthProof mb-4 h-100 mt-0'
                    onChange={this.onUploadAvatar}
                    multiple
                    maxFiles={1}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    Upload token avatar
                </FileZone>
                </Row>
              </Col>
            </Row>
            <EarthButton disabled={!isPublishable || publishing} flex>
              {
                publishing ?
                  <Spinner />
                  :
                  'Publish'
              }
            </EarthButton>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

