import axios from 'axios';
import { conf } from '../../utils';
import { getToken } from './index';
import HTTPError from '../HTTPError';

export const users = {
  signIn: async (username, password) => {
    const url = conf('apiUrl') + '/user/signin';
    const isEmail = username.includes('@') && username.includes('.');
    const body = {
      [isEmail ? 'email' : 'earthName']: username,
      password,
    };
  
    try {
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  signUp: async (earthName, email, password) => {
    const url = conf('apiUrl') + '/user/signup';
    const body = {
      earthName,
      password,
      email,
    };
  
    try {
      await axios.post(url, body);
      return true;
    } catch (error) {
      return false;
    }
  },
  resendActivationEmail: async () => {
    const url = conf('apiUrl') + '/user/activate';
    try {
      const res = await axios.patch(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  getSelf: async () => {
    const url = conf('apiUrl') + '/user';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getActivity: async (type) => {
    const url = `${conf('apiUrl')}/user/activity${type ? '/' + type : ''}`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getMetrics: async metric => {
    const url = `${conf('apiUrl')}/user/metrics${metric ? '/' + metric : ''}`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  getSessions: async () => {
    const url = `${conf('apiUrl')}/user/sessions`;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  },
  expireSession: async id => {
    const url = `${conf('apiUrl')}/user/sessions/${id}`;
    try {
      const res = await axios.delete(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch ({ response }) {
      if (response && response.data.error) return { error: HTTPError(response.data.error) }
      return { error: HTTPError() }
    }
  }
}
