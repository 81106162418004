import axios from 'axios';
import { conf } from '../../utils';
import { getToken } from './index';
import HTTPError from '../HTTPError';

export const utils = {
  getAlerts: async () => {
    const url = conf('apiUrl') + '/feed'
    try {
      const res = await axios.get(url, { headers: { 'content-type': 'multipart/form-data', Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  }
}