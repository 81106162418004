import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { branding } from '../../utils';
import './styles.scss';

export const EarthLogo = ({ size, type, className }) => {
  const imageStyle = `Image Image__${size}`;
  const logo = type === 'dark' ? branding.darkLogo : branding.lightLogo;

  return <img src={logo} className={cn(imageStyle, className)} alt="logo" />;
};

EarthLogo.defaultProps = {
  size: 'medium',
  type: 'light',
  className: null,
};

EarthLogo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
};
