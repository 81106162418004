/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Container, FormInput } from "shards-react";
import './styles.scss';
import { EarthWalletCake, EarthText, EarthCard, EarthInput } from "../../../components";
import { EarthMarketList } from './EarthMarketList';
import { EarthMarketOrderList } from './EarthMarketOrderList';
import { EarthMarketHistory } from './EarthMarketHistory'
import { BuySellForm } from './BuySellForm'
import { EarthMarketOverview } from './EarthMarketOverview';
import { balances } from '../../../controllers/requests';
import { branding } from "../../../utils";
import EarthWallet from "../EarthWallet";
import Cake from "../../../components/Charts/Cake/Cake";

class Projects extends React.Component {

  state = { sdg: 0, equityTokens: [], selectedMarket: '', filter: '' };

  componentDidMount() {
    balances.getBalances()
      .then(equityTokens => this.setState({ equityTokens }))
  }

  onSDGSelection = () => {

  }

  renderPortfolioCard = () => (
    <EarthCard className="send-receive-container mb-2">
      <div className='row'>
        <div className='col-12 col-md-6'>
          <EarthWallet />
        </div>
        <div className='col-12 col-md-6'>
          <Cake tokens={this.state.equityTokens} />
        </div>
      </div>
    </EarthCard>
  )

  renderMarketCard = () => (
    <div className='EarthCard h-100'>
      <div className='row mb-3'>
        <div className='col-4'>
          <EarthText fontStyle='bold'>Market</EarthText>
        </div>
        <div className='col'>
          <FormInput
            style={{ height: 22 }}
            onChange={({ target: { value } }) => this.setState({ filter: value })}
          />
        </div>
      </div>
      <div className='divider' />

      <EarthMarketList
        filter={this.state.filter}
        selectedMarket={this.state.selectedMarket}
        navigate={selectedMarket => this.setState({ selectedMarket })}
      />
    </div>
  )

  renderMarketOverview = () => (
    <EarthMarketOverview
      market={this.state.selectedMarket}
    />
  )

  renderBuySell = () => (
    <BuySellForm
      market={this.state.selectedMarket}
    />
  )

  renderSellOrders = () => (
    <div className='EarthCard h-100'>
      <EarthText fontStyle='bold'>Buy Orders</EarthText>
      <div className='divider' />
      <EarthMarketOrderList type='sell' market={this.state.selectedMarket} />
    </div>
  )

  renderBuyOrders = () => (
    <div className='EarthCard h-100'>
      <EarthText fontStyle='bold'>Sell Orders</EarthText>
      <div className='divider' />
      <EarthMarketOrderList type='buy' market={this.state.selectedMarket} />
    </div>
  )

  renderMarketHistory = () => (
    <div className='EarthCard h-100'>
      <EarthText fontStyle='bold'>Historical Orders</EarthText>
      <div className='divider' />
      <EarthMarketHistory market={this.state.selectedMarket} />
    </div>
  )

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <div className='row'>
          <div className="col">
            {this.renderPortfolioCard()}
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-12 col-xl-3'>
            {this.renderMarketCard()}
          </div>
          <div className='col-12 col-xl-6'>
            {this.renderMarketOverview()}
          </div>
          <div className='col-12 col-xl-3'>
            {this.renderBuySell()}
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-xl-3'>
            {this.renderSellOrders()}
          </div>
          <div className='col-12 col-xl-3'>
            {this.renderBuyOrders()}
          </div>
          <div className='col-12 col-xl-6'>
            {this.renderMarketHistory()}
          </div>
        </div>
      </Container>
    );
  }
}

export default Projects;