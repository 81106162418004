import React from 'react';
import { EarthText } from '../EarthText/EarthText';
import { EarthActionAvatar } from './EarthActionAvatar';
import './styles.scss';
import moment from 'moment';

export const EarthActivityItem = ({ type, empty, title, label, value, description, cost, amount, date }) => {
  return (
    <div className="EarthActivityItem">
      {
        empty ?
          <div className='EarthActivityItem__empty'>
            <EarthText fontStyle='bold'>Ooops! You haven't registered any activity yet...!</EarthText>
          </div>
          :
          <>
            <EarthActionAvatar className="EarthActivityItem__circle" type={type} />

            <div className="container-1">
              <EarthText className="EarthActivityItem__title" size="small" fontStyle="bold">
                {title || label}
              </EarthText>
              <EarthText className="EarthActivityItem__description" size="xsmall">
                {description || value}
              </EarthText>
            </div>

            <div className="container-2">
              <EarthText className="EarthActivityItem__amount" size="small" fontStyle="bold">
                {amount && `${amount} units x`} {cost && `${cost} USD`}
              </EarthText>
              <EarthText className="EarthActivityItem__date" size="xsmall">
                {moment(date).calendar()}
              </EarthText>
            </div>
          </>
      }
    </div>
  );
};
