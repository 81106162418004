/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Container, Row, FormInput, FormSelect } from "shards-react";
import { EarthImageCard, EarthText, Spinner, EarthCard } from "../../../components";
import { projects as request } from '../../../controllers/requests';
import { SDGButtons } from '../../../components';
import './styles.scss';

class Projects extends React.Component {

  state = {
    sdgs: {}, projects: [], loadingProjects: false,
    loadingEquity: false, equityTokens: [], nameFilter: '', tokenFilter: '', statusFilter: '', categoryFilter: false
  };

  componentDidMount() {
    this.setState({ loadingEquity: true, loadingProjects: true });
    request.getProjects().then(({ projects }) => this.setState({ projects, loadingProjects: false }));
  }

  render() {
    const { projects, sdgs, loadingProjects, nameFilter, tokenFilter, statusFilter, categoryFilter } = this.state;
    const shouldApplyFilter = (new Set(Object.values(sdgs))).has(true);

    const projectsFiltered = projects.filter(({ name, equityToken, category, stripe }) => (
      name.toLowerCase().includes(nameFilter.toLowerCase()) &&
      equityToken.toLowerCase().includes(tokenFilter.toLowerCase()) &&
      (statusFilter ? stripe === true : true) &&
      (categoryFilter === false ? true : categoryFilter === category)
    ))

    return (
      <EarthCard>
        <EarthText size='xlarge' fontStyle='bold'>Investor overview</EarthText>
        <div className='divider mb-4' />

        <EarthText size='xlarge' fontStyle='bold'>Filters</EarthText>
        <SDGButtons values={sdgs} onChange={sdgs => this.setState({ sdgs })} />

        <div className='row mb-4'>
          <div className='col-6 col-md-3 mt-3'>
            <label>
              Project Name
            </label>
            <FormInput
              onChange={({ target: { value } }) => this.setState({ nameFilter: value })}
            />
          </div>
          <div className='col-6 col-md-3 mt-3'>
            <label>
              Token Name
            </label>
            <FormInput
              onChange={({ target: { value } }) => this.setState({ tokenFilter: value })}
            />
          </div>
          <div className='col-6 col-md-3 mt-3'>
            <label>
              Status
            </label>
            <FormSelect onChange={({ target: { value } }) => this.setState({ statusFilter: value === 'Ready to invest' })}>
              <option>All</option>
              <option>Ready to invest</option>
            </FormSelect>
          </div>
          <div className='col-6 col-md-3 mt-3'>
            <label>
              Category
            </label>
            <FormSelect onChange={({ target: { value } }) => this.setState({ categoryFilter: value === 'Choose...' ? false : value })}>
              <option>Choose...</option>
              <option>Grasslands</option>
              <option>Rainforest</option>
              <option>Eco Community</option>
              <option>Earth Saver</option>
            </FormSelect>
          </div>
        </div>

        <Row className='mt-2 projects-container'>
          {
            loadingProjects &&
            <div className='row justify-content-center w-100' style={{ alignContent: 'center', height: 150 }}>
              <Spinner />
            </div>
          }

          {
            !loadingProjects && projectsFiltered.map((project, idx) => {
              if (shouldApplyFilter) {
                let shouldRender;
                Object.keys(project.sdgs).map(sdg => !shouldRender && (shouldRender = sdgs[sdg]))
                if (!shouldRender) return null;
              }
              return (
                <EarthImageCard
                  key={idx}
                  sdgs={project.sdgs}
                  backgroundImage={project.images[0]}
                  raisedPercentage={(project.raised / project.fundTarget) * 100}
                  description={project.smallDescription}
                  investment={project.minInvestment}
                  raised={project.raised}
                  investors={project.investors}
                  daysLeft={project.daysToGo}
                  location={project.location}
                  projectName={project.name}
                  badgeLabel={project.category}
                  finance={project.finance}
                  avatar={project.avatar}
                  navigate={() => this.props.history.push(`/project/${project.urlPath}`)}
                />
              )
            })}
        </Row>
      </EarthCard>
    );
  }
}

export default Projects;