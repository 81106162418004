/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint jsx-a11y/anchor-has-content: 0 */


import React from "react";
import { Progress, CardBody, Row, FormInput, FormCheckbox, Col, Badge } from "shards-react";
import {
  ShareBatch, EarthCard, EarthText, EarthButton,
  EarthTabs, EarthTabItem, EarthModal, EarthTimeline, Spinner
} from '../../../components';
import { EarthQuestion } from "./EarthQuestion";
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux'
import './styles.scss';
import { projects } from '../../../controllers/requests';
import ReactQuill from "react-quill";
import { conf } from '../../../utils';
import 'react-quill/dist/quill.bubble.css';
import Checkout from "../../../components/Checkout/Checkout";
import CommentBox from './CommentBox';
import { isNumeric } from "../../../utils/validations";

class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shares: '',
      showModal: false,
      tab: 'story',
      loading: true,
      imagesUrl: [],
      avatarUrl: ''
    };
  }

  componentDidMount() {
    const { urlPath } = this.props.match.params;
    projects.getProjectById(urlPath)
      .then(project => {
        this.setState({ ...project, loading: false });
        const imagesUrl = project.images.map(imageId => ({ original: conf('apiUrl') + '/static/images/' + imageId }));
        const avatarUrl = conf('apiUrl') + '/static/images/' + project.avatar;
        this.setState({ imagesUrl, avatarUrl });
      })
  }

  onPurchase = billingInfo => projects.buyShares(this.state.urlPath, this.state.shares, billingInfo)
  onPurchaseComplete = result => result && this.setState({ showModal: false });
  onBuy = () => this.setState({ showModal: true })
  swapTab = tab => this.setState({ tab });
  onShareChange = event => this.setState({ shares: event.target.value });

  render() {
    const { shares, tab, loading, imagesUrl, avatarUrl, showModal } = this.state;
    const project = this.state;
    const { currency } = this.props;
    const finalPrice = shares ? Number(shares) * Number(project.price) : '';
    return (
      loading ?
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh'
        }}
        >
          <Spinner />
        </div>
        :
        <>
          <EarthCard className="send-receive-container" style={{ padding: 0 }}>
            <CardBody>
              <Row className='mb-4'>
                <div className='col-12 col-lg-8'>
                  <ImageGallery additionalClass='image-gallery-cropped' items={imagesUrl} />
                </div>
                <div className='col-12 col-lg-4'>
                  <Badge
                    pill
                    className={`card-post__category mb-4`}
                  >
                    {project.badge}
                  </Badge>
                  <EarthText className='mb-2' fontStyle='bold' size='large'>{project.name}</EarthText>
                  <EarthText fontStyle={'light'} size='medium'>
                    {project.description}
                  </EarthText>
                  <div className='divider' />
                  <EarthText size='small'>
                    Token {project.equityToken} | Volatibility {project.volatibility} %
                  </EarthText>
                  <EarthText fontStyle='bold' size='small'>
                    {project.price} {currency.toUpperCase()} x token | Min investment {project.minInvestment}
                  </EarthText>
                  <div className='divider mt-2 mb-4' />
                  <EarthText className='inline mr-2' size={'large'} fontStyle={'bold'}>
                    State of this project
                  </EarthText>
                  <EarthText size={'small'} className='inline' fontStyle={'bold'} color='green'>
                    {'RAISING'}
                  </EarthText>
                  <div className='mt-3 mb-4'>
                    <Row className='mb-4'>
                      <Col style={{ textAlign: 'center' }}>
                        <EarthText size='small'>${project.raised} raised</EarthText>
                      </Col>
                      <Col style={{ textAlign: 'center' }}>
                        <EarthText size='small'>{project.investors} backers</EarthText>
                      </Col>
                      <Col style={{ textAlign: 'center' }}>
                        <EarthText size='small'>{((project.raised / project.fundTarget) * 1.0).toFixed(2) * 100}%</EarthText>
                      </Col>
                    </Row>
                    <Progress value={project.raisedPercentage} />
                    <ShareBatch />
                  </div>
                </div>
              </Row>
              <div style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'end' }}>
                <EarthText className='mb-1' fontStyle='bold'>How many tokens do you want to buy?</EarthText>
                <FormInput onChange={this.onShareChange} value={shares} placeholder='Units' />
                <EarthButton
                  className='mt-2'
                  flex onClick={this.onBuy} disabled={!isNumeric(shares) || !project.stripe}
                >
                  BUY
                    </EarthButton>
                {!project.stripe && <label style={{ color: '#800' }}>Investments are not open yet on this project.</label>}
                <div className='row' style={{ justifyContent: 'space-between', margin: 'inherit' }}>
                  <EarthText className='inline' size='small' fontStyle='bold'>Total: ${finalPrice}</EarthText>
                  <FormCheckbox className='inline' disabled checked={false}>Use funds on my account</FormCheckbox>
                </div>
              </div>
              <EarthTabs>
                <EarthTabItem
                  onClick={() => this.swapTab('story')}
                  icon=""
                  title="Story"
                  isActive={tab === 'story'}
                >
                  <ReactQuill
                    value={project.story}
                    readOnly={true}
                    theme={"bubble"}
                  />
                </EarthTabItem>
                <EarthTabItem
                  onClick={() => this.swapTab('faq')}
                  icon=""
                  title="FAQ"
                  isActive={tab === 'faq'}
                >
                  {!project.faq.length && (
                    <div className='h-100 w-100 comment-box' align='center'>
                      <i className="material-icons">notifications</i>
                      <EarthText>Feature currently unavailable</EarthText>
                    </div>
                  )}
                  {project.faq.map(({ question, answer }) => <EarthQuestion question={question} answer={answer} />)}
                </EarthTabItem>
                <EarthTabItem
                  onClick={() => this.swapTab('updates')}
                  icon=""
                  title="Updates"
                  isActive={tab === 'updates'}
                >
                  <EarthTimeline />
                </EarthTabItem>
                <EarthTabItem
                  onClick={() => this.swapTab('comments')}
                  icon=""
                  title="Comments"
                  isActive={tab === 'comments'}
                >
                  <CommentBox />
                </EarthTabItem>
              </EarthTabs>
            </CardBody>
          </EarthCard >
          <EarthModal
            onClose={() => this.setState({ showModal: false })}
            show={showModal}
            style={{ textAlign: 'center' }}
          >
            <Checkout
              onPurchase={this.onPurchase} onPurchaseComplete={this.onPurchaseComplete} finalPrice={finalPrice}
              label={`You are about to buy ${shares} units for ${finalPrice} USD`}
            />
          </EarthModal>
        </>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.root.user,
  };
}

export default connect(mapStatetoProps)(Projects);