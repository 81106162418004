import axios from 'axios';
import { conf } from '../../utils';
import { getToken } from './index';
import HTTPError from '../HTTPError';

export const alerts = {
  getAlerts: async () => {
    const url = conf('apiUrl') + '/feed'
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  newAlert: async (location, message) => {
    const url = conf('apiUrl') + '/feed'
    try {
      const body = {
        label: message,
        location
      }
      const res = await axios.post(url, body, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  },
  removeAlert: async alertId => {
    const url = conf('apiUrl') + '/feed/' + alertId
    try {
      const res = await axios.delete(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      return false;
    }
  }
}
