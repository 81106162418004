import React from 'react'
import { Nav } from 'shards-react';
import Profile from './Profile';
import './styles.scss';
import { useState } from 'react';
import { branding } from '../../utils';
import { connect } from 'react-redux';

const Header = ({ history, role}) => {
  const [navbarState, handleNavbar] = useState(false);
  const navigate = where => {
    handleNavbar(false);
    history.push(where)
  }
  const { pathname } = history.location;

  return (
    <>
      <Nav className={`navbar-responsive ${!navbarState ? 'navbar-blurred' : undefined}`}>
        <div className={`container navbar-flexcont`}>
          <a href='/' className={`subtitle inline my-auto ${navbarState ? 'white' : ''}`}>
            <img className='navbar-logo' src={branding.lightLogo} />
          </a>
          <div className='navlinks'>
            <li><p className={`${pathname === '/dashboard' ? 'path-active' : undefined}`} onClick={() => navigate('/dashboard')} >Dashboard</p></li>
            <li><p className={`${pathname === '/projects' ? 'path-active' : undefined}`} onClick={() => navigate('/projects')}>Invest</p></li>
            <li><p className={`${pathname === '/wallet' ? 'path-active' : undefined}`} onClick={() => navigate('/wallet')}>Liquid Wallet</p></li>
            <li><p className={`${pathname === '/projects/market' ? 'path-active' : undefined}`} onClick={() => navigate('/projects/market')}>market</p></li>
            {
              role === 'admin' && <li><p className={`${pathname.includes('/admin') ? 'path-active' : undefined}`} onClick={() => navigate('/admin')}>admin</p></li>
            }
          </div>
          <Profile />
          <div className='burger-wrapper'>
            <div className='wrapper' onClick={() => handleNavbar(!navbarState)}>
              <div className={navbarState ? "open" : ""}>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </Nav>
      {
        navbarState &&
        <div className='collapsable-wrapper'>
          <ul className='navlink-collapsable'>
            <li><p className={`${pathname === '/dashboard' ? 'path-active' : undefined}`} onClick={() => navigate('/dashboard')} >Dashboard</p></li>
            <li><p className={`${pathname === '/projects' ? 'path-active' : undefined}`} onClick={() => navigate('/projects')}>Invest</p></li>
            <li><p className={`${pathname === '/wallet' ? 'path-active' : undefined}`} onClick={() => navigate('/wallet')}>Liquid Wallet</p></li>
            <li><p className={`${pathname === '/projects/market' ? 'path-active' : undefined}`} onClick={() => navigate('/projects/market')}>market</p></li>
          </ul>
        </div>
      }
    </>
  )
}


const mapStatetoProps = state => {
  return { role: state.root.user.role }
}

export const EarthHeader = connect(mapStatetoProps)(Header);