import React from "react";
import { Container } from "shards-react";
import { ProjectEditor } from '../../../components'
import { projects } from '../../../controllers/requests';
import { toast } from "react-toastify";

const EarthProjectCreate = ({ history }) => (
  <Container fluid className="main-content-container px-4 pb-4">
    <ProjectEditor onSubmit={async project => {
      const res = await projects.createProject(project);
      if (res) {
        toast.success('Project created');
        history.push('/admin');
      } else toast.error('Error while creating project');
    }} />
  </Container>
);

export default EarthProjectCreate;
