import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { EarthText } from '../EarthText/EarthText';
import './styles.scss';

export const EarthTabItem = ({ isActive, title, icon, iconDirection, onClick }) => {
  const tabItemStyles = cn({
    EarthTabItem: true,
    EarthTabItem__active: isActive,
  });

  return (
    <EarthText fontStyle="bold" onClick={() => onClick()} className={tabItemStyles}>
      {iconDirection === 'left' && icon}
      <span className={icon ? 'EarthTabItem__title' : ''}>{title}</span>
      {iconDirection === 'right' && icon}
    </EarthText>
  );
};

EarthTabItem.defaultProps = {
  isActive: false,
  iconDirection: 'left',
};

EarthTabItem.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.any,
  iconDirection: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
};
