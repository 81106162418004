import cn from 'classnames';
import React from 'react';
import { EarthCard } from '../EarthCard/EarthCard';
import { EarthActivityItem } from './EarthActivityItem';
import './styles.scss';

export const EarthActivityCard = ({ activities, className }) => {
  return (
    <EarthCard className={cn('EarthActivityCard', className)}>
      {
        activities.length ? activities.map(
          ({ type, title, description, cost, amount, timestamp, value, label }, index) => (
            <EarthActivityItem
              key={`eart-activity-card-${index}`}
              type={type}
              value={value}
              label={label}
              title={title}
              description={description}
              amount={amount}
              cost={cost}
              date={timestamp}
            />
          )
        )
          :
          <EarthActivityItem
            empty
          />
      }
    </EarthCard>
  );
};
