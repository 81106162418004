import React, { Component } from 'react';
import { EarthText, SDGButtons, Spinner } from '../../../components';
import { markets } from '../../../controllers/requests';
import { conf } from '../../../utils';
import Chart from '../../../components/Charts/Overview/Overview'
import './styles.scss';

export class EarthMarketOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, market: {}, loadingChart: false, metrics: [] };
  }

  componentDidMount() {
    const { market } = this.props;
    if (market) this.pullMarketById()
  }

  componentDidUpdate({ market: prevMarket }) {
    const { market } = this.props;
    if ((market !== prevMarket) && market) this.pullMarketById();
  }

  pullMarketById = () => {
    const { market } = this.props;
    this.setState({ loading: true, loadingChart: true });
    markets.getMarketById(market)
      .then(market => this.setState({ market, loading: false }));
    markets.getMetricsById(market)
      .then(metrics => this.setState({ metrics, loadingChart: false }));
  }

  renderNoMarket = () => (
    <div className='select-market' style={{ marginTop: '20%' }}>
      <i className='material-icons'>storefront</i>
      <p className='select-market'>Select a market to continue</p>
    </div>
  )

  renderOverview = () => {
    const { metrics, loadingChart } = this.state;
    const { name, volatibility, initialPrice, lastPrice, equityToken, avatar, sdgs } = this.state.market;
    return (
      <div>
        <div className='row mb-4' style={{ alignItems: 'center' }}>
          <div className='col-12 col-xl-4  mt-3'>
            <div className='icon-name'>
              <img height={40} width={40} alt='token' className='rounded-circle mr-3' src={conf('apiUrl') + '/static/images/' + avatar} />
              <div>
                <EarthText fontStyle='bold' size='xsmall'>{name}</EarthText>
                <EarthText fontStyle='bold'>{equityToken}</EarthText>
              </div>
            </div>
          </div>
          <div className='col-12 col-xl-8 mt-3'>
            <div className='row'>
              <div className='col text-center'>
                <EarthText fontStyle='bold' size='xsmall'>Price</EarthText>
                <EarthText className='mt-2' size='xsmall'>{initialPrice}</EarthText>
              </div>
              <div className='col text-center'>
                <EarthText fontStyle='bold' size='xsmall'>Volatibility</EarthText>
                <EarthText className='mt-2' size='xsmall'>{volatibility}</EarthText>
              </div>
              <div className='col text-center'>
                <EarthText fontStyle='bold' size='xsmall'>Last Price</EarthText>
                <EarthText className='mt-2' size='xsmall'>{lastPrice}</EarthText>
              </div>
              <div className='col text-center'>
                <EarthText fontStyle='bold' size='xsmall'>Change</EarthText>
                <EarthText className='mt-2' size='xsmall'>{((lastPrice / initialPrice) * 100) - 100}</EarthText>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <SDGButtons smaller onlyShow={sdgs} endToStart />
        </div>
        {loadingChart ?
          <Spinner />
          :
          <div style={{ height: '100%' }}>
            <Chart metrics={metrics} />
          </div>
        }
      </div>
    )
  }

  render() {
    const { market } = this.props;
    const { loading } = this.state;

    return (
      <div className='EarthCard h-100'>
        {market && (loading ? <Spinner /> : this.renderOverview())}
        {!market && this.renderNoMarket()}
      </div>
    );
  }
}
