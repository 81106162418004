import axios from 'axios';
import { conf } from '../../utils';
import { getToken } from './index';
import HTTPError from '../HTTPError';

export const balances = {
  getBalances: async () => {
    const url = conf('apiUrl') + '/balances';
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  getBalancesByToken: async token => {
    const url = conf('apiUrl') + '/balances/' + token;
    try {
      const res = await axios.get(url, { headers: { Authorization: getToken() } });
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
}
