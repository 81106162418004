import React from 'react';
import { EarthText } from './'
import { conf } from '../utils';

export const BalanceCard = ({ token, name, balance, avatar }) => (
  <div className='row mb-2'>
    <div className='col-2 col-md-1 my-auto'>
      <img
        className='token-image'
        width={35} height={35} src={`${conf('apiUrl')}/static/images/${avatar}`}
      />
    </div>
    <div className='col-6 my-auto'>
      <EarthText size='xsmall'>{token}</EarthText>
      <EarthText>{name}</EarthText>
    </div>
    <div className='col-4 col-md-5 my-auto' align='right'>
      <EarthText>{`$${balance}`}</EarthText>
    </div>
  </div>
)