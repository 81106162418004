import React, { useState } from 'react';
import CreditCardInput from 'react-credit-card-input';
import { FormInput, Button } from 'shards-react';
import countryNames from '../../utils/countries';
import { isValidExpiration, isValidCvc, isCreditCard } from '../../utils/validations';
import { toast } from 'react-toastify';
import { Spinner } from '../../components';
import './styles.scss'
export default ({ onPurchase, onPurchaseComplete, label }) => {
  const [name, onChangeName] = useState('');
  const [number, onChangeNumber] = useState('');
  const [cardExpiry, onChangeExpiry] = useState('');
  const [cvc, onChangeCvc] = useState('');
  const [zip, onChangeZip] = useState('');
  const [country, onChangeCountry] = useState('');
  const [isPaying, onPaying] = useState(false);

  const onPurchaseRequest = () => {
    const billing = { name, number, cardExpiry, cvc, zip, country };
    onPaying(true);
    onPurchase(billing).then(res => {
      onPaying(false);
      if (!res.error) {
        toast.success('Success');
        onPurchaseComplete(true);
      }
      else {
        toast.error(res.error);
        onPurchaseComplete(false);
      }
    })
  }

  const isButtonDisabled = !isCreditCard(number) || !isValidCvc(cvc) || !isValidExpiration(cardExpiry) || !country || !zip || !name;

  return (
    <form id='form-checkout'>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <label className='checkout-item isLabel'>
            Cardholder Name
          </label>
          <FormInput
            value={name} onChange={({ target: { value } }) => onChangeName(value)}
            placeholder='Your Name' className='w-100'
          />
        </div>
        <div className='col-12 col-md-6'>
            <label className='checkout-item isLabel'>
              Card details
            </label>
            <CreditCardInput
              containerClassName='card-input'
              cardImageClassName='card-image'
              cardNumberInputProps={{ value: number, onChange: ({ target: { value } }) => onChangeNumber(value) }}
              cardExpiryInputProps={{ value: cardExpiry, onChange: ({ target: { value } }) => onChangeExpiry(value) }}
              cardCVCInputProps={{ value: cvc, onChange: ({ target: { value } }) => onChangeCvc(value) }}
              fieldClassName="input"
            />
        </div>
      </div>
      <div className='row mb-4 mt-4'>
        <div className='col-12 col-md-6'>
          <label className='checkout-item isLabel'>
            Billing ZIP code
          </label>
          <FormInput
            value={zip} onChange={({ target: { value } }) => onChangeZip(value)}
            placeholder='ZIP code' className='w-100'
          />
        </div>
        <div className='col-12 col-md-6'>
          <label className='checkout-item isLabel'>
            Country
          </label>
          <select className='form-control w-100' value={country} onChange={({ target: { value } }) => onChangeCountry(value)}>
            <option value={''}>Choose a country</option>)}
              {countryNames.map(({ name, code }) => <option key={code} value={code}>{name}</option>)}
          </select>
        </div>
      </div>
      <Button outline={isButtonDisabled} color='primary' onClick={onPurchaseRequest}
        className={isButtonDisabled ? 'disabled-button' : undefined}
        disabled={isButtonDisabled} style={{ width: '100%' }}
      >
        {
          isPaying ?
          <Spinner/>
            :
            'Buy'
        }
      </Button>
      <p className='text-center'>{label}</p>
    </form>
  );
};